import "./main.css";

import gallerySlider from "./js/gallery-slider.js";
import lightMove from "./js/light-move.js";
import navigationOverlayToggle from "./js/navigation-overlay-toggle.js";
import navigationSlidinOnPageScroll from "./js/navigation-slidin-on-page-scroll.js";
import openerWords from "./js/opener.js";

document.addEventListener("DOMContentLoaded", () => {
  /*
  The DOMContentLoaded event fires when the initial HTML document has 
  been completely loaded and parsed, without waiting for stylesheets, 
  images, and subframes to finish loading.
  */
  navigationOverlayToggle();
  navigationSlidinOnPageScroll();
  openerWords();
  gallerySlider();
  lightMove();
});

window.addEventListener("load", () => {
  /*
  The load event is fired when the whole page has loaded, 
  including all dependent resources such as stylesheets and images
  */
});
